import React, { ChangeEvent, useEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LaunchIcon from '@mui/icons-material/Launch';
import Paper from '@mui/material/Paper';
import { Button, Select, MenuItem, SelectChangeEvent, FormControlLabel, Checkbox } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const backendUrl = "https://internal-tools-api.prod.lnkup.cloud";

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface User {
  name: string;
  email: string;
  profileLink: string;
  registrationDate: string;
  status: number;
}

interface UserTableProps {
  list: User[];
  updateNotifier: React.Dispatch<React.SetStateAction<User[]>>;
}

interface Group {
  name: string;
  owner: string;
  link: string;
  memberCount: string;
  creationDate: string;
}

interface GroupTableProps {
  list: Group[];
}

function ActivateUser(email: string): boolean {
  // let formData = new FormData();
  // formData.append('user_email', email);
  let usp = new URLSearchParams();
  usp.set('user_email', email)

  fetch(backendUrl + "/ActivateUser", {
    method: 'POST',
    body: usp,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed");
      }
      alert("Activated");
      return true;
    })
    .catch(() => {
      alert("Failed to activate");
      return false;
    });

  return true;
}

function UsersTable({ list, updateNotifier }: UserTableProps) {
  return (<TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Registered on</TableCell>
          <TableCell>Activation</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((entry: any) => (
          <TableRow
            key={entry.email}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                {entry.name}&nbsp;
                <a href={entry.profileLink} target="_blank" rel="noreferrer noopener">
                  <LaunchIcon fontSize="small" />
                </a>
              </div>
            </TableCell>
            <TableCell>{entry.email}</TableCell>
            <TableCell>{entry.registrationDate}</TableCell>
            <TableCell>
              {
                entry.status === 0 ?
                  <Button onClick={() => {
                    if (!ActivateUser(entry.email)) {
                      return;
                    }

                    const newList = list.map((item) => {
                      if (item.email === entry.email) {
                        const updatedItem = {
                          ...item,
                          status: 1,
                        };

                        return updatedItem;
                      }

                      return item;
                    });

                    updateNotifier(newList);
                  }}>Activate</Button>
                  : <Button disabled>Activated</Button>
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>);
}

function GroupsTable({ list }: GroupTableProps) {
  return (<TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Owner</TableCell>
          <TableCell>Member count</TableCell>
          <TableCell>Created on</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((entry: any) => (
          <TableRow
            key={entry.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                {entry.name}&nbsp;
                <a href={entry.link} target="_blank" rel="noreferrer noopener">
                  <LaunchIcon fontSize="small" />
                </a>
              </div>
            </TableCell>
            <TableCell>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                {entry.owner}&nbsp;
                <a href={entry.ownerProfileLink} target="_blank" rel="noreferrer noopener">
                  <LaunchIcon fontSize="small" />
                </a>
              </div>
            </TableCell>
            <TableCell>{entry.memberCount}</TableCell>
            <TableCell>{entry.creationDate}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>);
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function BulkUserAdd() {
  const [fileList, setFileList] = useState<FileList | null>(null)
  const [groupsList, setGroupsList] = useState<any>([])
  const [industriesList, setIndustriesList] = useState<any>([])
  const [selectedGroup, setSelectedGroup] = useState<number | null>(0)
  const [selectedIndustry, setSelectedIndustry] = useState<number | null>(0)
  const [sendGroupAddNotifications, setSendGroupAddNotifications] = useState<boolean>(false)

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileList(e.target.files);
  };

  const handleGroupChange = (event: SelectChangeEvent) => {
    setSelectedGroup(+event.target.value);
  };

  const handleIndustryChange = (event: SelectChangeEvent) => {
    setSelectedIndustry(+event.target.value);
  };

  const handleChangeGroupAddNotifications = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendGroupAddNotifications(event.target.checked);
  };

  useEffect(() => {
    fetch(backendUrl + "/GetGroups")
      .then((response) => response.json())
      .then((data) => {
        data.sort((a: any, b: any) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        })
        setGroupsList(data);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  useEffect(() => {
    fetch(backendUrl + "/GetIndustries")
      .then((response) => response.json())
      .then((data) => {
        data.sort((a: any, b: any) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        setIndustriesList(data);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  const handleUploadClick = () => {
    if (!fileList) {
      return;
    }

    const data = new FormData();
    data.append(`csv`, fileList[0], fileList[0].name);
    if (selectedGroup !== null) {
      data.append('groupId', `${selectedGroup}`);
    }
    if (selectedIndustry !== null) {
      data.append('industryId', `${selectedIndustry}`);
    }
    data.append('sendGroupAddNotifications', sendGroupAddNotifications ? "1" : "0");

    fetch(backendUrl + "/BulkAddUsers", {
      method: 'POST',
      body: data,
    })
      .then((res) => res.blob())
      .then((blob) => {
        var file = window.URL.createObjectURL(blob);
        window.location.assign(file);
      })
      .catch((err) => alert(err));
  };

  return (<div>
    <h4>Choose a CSV file</h4>
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
    >
      Upload files
      <VisuallyHiddenInput
        type="file"
        onChange={handleFileChange}
      />
    </Button> {fileList ? 'Chosen file: ' + fileList[0].name : ''}
    <br />
    <br />
    <div>File to upload must be in a CSV format. The first list must be a header and contain 3 values without quotes: "First name", "Last name", "Email".</div>
    <h4>Choose a group</h4>
    <div><Select value={selectedGroup ? `${selectedGroup}` : ''} onChange={handleGroupChange}>
      {groupsList && groupsList.map((entry: any) => (
        <MenuItem key={entry.id} value={entry.id}>{entry.title} {'('}{entry.userCount} members, created on {entry.createdOn}{')'}</MenuItem>
      ))}
    </Select>
    </div>
    <h4>Choose an industry</h4>
    <div><Select value={selectedIndustry ? `${selectedIndustry}` : ''} onChange={handleIndustryChange}>
      {industriesList && industriesList.map((entry: any) => (
        <MenuItem key={entry.id} value={entry.id}>{entry.name}</MenuItem>
      ))}
    </Select>
      <br />
    </div>
    <div>
      <FormControlLabel control={<Checkbox checked={sendGroupAddNotifications} onChange={handleChangeGroupAddNotifications} />} label="Send group add notifications to the users" />
    </div>
    <div>
      <br />
      <Button onClick={handleUploadClick} variant="contained" component="label" disabled={!selectedGroup || !selectedIndustry || !fileList ? true : false}>Upload</Button>
    </div>
  </div>)
}

function App() {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [userList, setUserList] = React.useState([] as any);
  const [groupList, setGroupList] = React.useState([]);
  const [stats, setStats] = React.useState({ usersRegisteredLastDay: 0, usersRegisteredLastWeek: 0, usersRegisteredTotal: 0, groupsCreatedLastDay: 0, groupsCreatedLastWeek: 0, groupsCreatedTotal: 0, dau: 0, mau: 0 });

  useEffect(() => {
    fetch(backendUrl + "/GetRecentUsers")
      .then((response) => response.json())
      .then((data) => {
        setUserList(data);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  useEffect(() => {
    fetch(backendUrl + "/GetRecentGroups")
      .then((response) => response.json())
      .then((data) => {
        setGroupList(data);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  useEffect(() => {
    fetch(backendUrl + "/GetStats")
      .then((response) => response.json())
      .then((data) => {
        setStats(data);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const exportUsers = () => {
    window.open(backendUrl + "/ExportUsers", "_self");
  }

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab label="Recent users" />
          <Tab label="Recent groups" />
          <Tab label="Bulk add users" />
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedTab} index={0}>
        <Button sx={{ float: 'right', backgroundColor: 'rgb(25, 118, 210)', color: 'white' }} onClick={exportUsers}>Export</Button>
        <h3>Users registered: last day - {stats.usersRegisteredLastDay}, last week - {stats.usersRegisteredLastWeek}, grand total - {stats.usersRegisteredTotal}</h3>
        <h3>Users active: daily - {stats.dau}, monthly - {stats.mau}</h3>
        <UsersTable list={userList} updateNotifier={setUserList} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <h3>Groups created: last day - {stats.groupsCreatedLastDay}, last week - {stats.groupsCreatedLastWeek}, grand total - {stats.groupsCreatedTotal}</h3>
        <GroupsTable list={groupList} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={2}>
        <h3>Bulk add users</h3>
        <BulkUserAdd />
      </CustomTabPanel>
    </Box>
  );
}

export default App;
